import React from "react";
import classes from "./Footer.module.scss";

const Footer = () => {
  let _content = null;
  // if (scrolled )
  _content = (
    <div className={classes.Footer}>
      <p>
        {
          "Probrain Soluções Neurotecnológicas para Saúde e Educação Ltda | CNPJ 32.841.596/0001-49"
        }
      </p>
      <p>
        {
          "Av. General Carneiro, nº 765, sala 03, Vila Martha,Sorocaba, Estado de São Paulo, 18043-003"
        }
      </p>
      <p>
        Copyright &copy; ProBrain Soluções Neurotecnológicas para Saúde e
        Educação Ltda
      </p>
      <p>
        V. 1.2.1
      </p>
    </div>
  );
  return _content;
};

export default Footer;
