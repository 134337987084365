import { GET_AUDIO_DIR } from "../constants/tests";
import {
  SECRET_KEY,
  AUDIOFOCO_LINK,
} from "../constants/api";
import Crypto from "crypto-js";


export const removeAscents = (text: string) => {
  return text
    .replace(/[ÁÀÂÃÄ]/g, "A")
    .replace(/[ÉÈÊË]/g, "E")
    .replace(/[ÍÌÎÏ]/g, "I")
    .replace(/[ÓÒÔÕÖ]/g, "O")
    .replace(/[ÚÙÛÜ]/g, "U")
    .replace(/[Ç]/g, "C")
    .replace(/[áàâãä]/g, "a")
    .replace(/[éèêë]/g, "e")
    .replace(/[íìîï]/g, "i")
    .replace(/[óòôõö]/g, "o")
    .replace(/[úùûü]/g, "u")
    .replace(/[ç]/g, "c");
};

/**
 *
 * @param {0 - 100} score
 * @description return a objecjet {color: hexa, colorBorder: hexa}, red / yellow / green
 */
export const getColorsScores = (
  score: any
) => {
  score = parseInt(score);
  const color = "#cbe0e433";
  const colorBorder = "#007085";
  // if (score < 50) {
  //   color = "#ffcdcd";
  //   colorBorder = "#d6373a";
  // } else if (score >= 50 && score <= 80) {
  //   color = "#ffffcb";
  //   colorBorder = "#c5a82f";
  // }
  return {
    color: color,
    colorBorder: colorBorder,
  };
};

//INTERVALOS DE SCORE DOS TESTES
export const getColorsScoreEvolucao = (
  score: any
) => {
  score = parseInt(score);
  const colorTime = "#cbe0e433";
  const colorBorderTime = "#007085";
  // let colorTime = "#ffcdcd";
  // let colorBorderTime = "#d6373a";
  // if (score >= 0) {
  //   colorTime = "#e6ffdc";
  //   colorBorderTime = "#24660a";
  // }
  return {
    colorScoreEvolucao: colorTime,
    colorBorderScoreEvolucao: colorBorderTime,
  };
};
export const getColorsScoreLocalizacaoSonora = (
  score: any
) => {
  score = parseInt(score);
  const color = "#cbe0e433";
  const colorBorder = "#007085";
  // let color = "#e6ffdc";
  // let colorBorder = "#24660a";

  // if (score <= 60) {
  //   color = "#ffcdcd";
  //   colorBorder = "#d6373a";
  // } else if (score > 60 && score <= 85) {
  //   color = "#ffffcb";
  //   colorBorder = "#c5a82f";
  // }
  return {
    colorScoreAvaliation: color,
    colorBorderScoreAvaliation: colorBorder,
    colorScoreReavaliation: color,
    colorBorderScoreReavaliation: colorBorder,
  };
};

export const getColorsScoreFechamentoOd = (
  score: any
) => {
  score = parseInt(score);
  const color = "#cbe0e433";
  const colorBorder = "#007085";
  // let color = "#e6ffdc";
  // let colorBorder = "#24660a";
  // if (score <= 70) {
  //   color = "#ffcdcd";
  //   colorBorder = "#d6373a";
  // } else if (score > 70 && score <= 90) {
  //   color = "#ffffcb";
  //   colorBorder = "#c5a82f";
  // }
  return {
    colorScoreAvaliation: color,
    colorBorderScoreAvaliation: colorBorder,
    colorScoreReavaliation: color,
    colorBorderScoreReavaliation: colorBorder,
  };
};
export const getColorsScoreFechamentoOe = (
  score: any
) => {
  score = parseInt(score);
  const color = "#cbe0e433";
  const colorBorder = "#007085";
  // let color = "#e6ffdc";
  // let colorBorder = "#24660a";
  // if (score <= 75) {
  //   color = "#ffcdcd";
  //   colorBorder = "#d6373a";
  // } else if (score > 75 && score <= 90) {
  //   color = "#ffffcb";
  //   colorBorder = "#c5a82f";
  // }
  return {
    colorScoreAvaliation: color,
    colorBorderScoreAvaliation: colorBorder,
    colorScoreReavaliation: color,
    colorBorderScoreReavaliation: colorBorder,
  };
};
export const getColorsScoreFiguraFundoOd = (
  score: any
) => {
  score = parseInt(score);
  const color = "#cbe0e433";
  const colorBorder = "#007085";
  // let color = "#e6ffdc";
  // let colorBorder = "#24660a";
  // if (score <= 75) {
  //   color = "#ffcdcd";
  //   colorBorder = "#d6373a";
  // } else if (score > 75 && score <= 90) {
  //   color = "#ffffcb";
  //   colorBorder = "#c5a82f";
  // }
  return {
    colorScoreAvaliation: color,
    colorBorderScoreAvaliation: colorBorder,
    colorScoreReavaliation: color,
    colorBorderScoreReavaliation: colorBorder,
  };
};
export const getColorsScoreFiguraFundoOe = (
  score: any
) => {
  score = parseInt(score);
  const color = "#cbe0e433";
  const colorBorder = "#007085";
  // let color = "#e6ffdc";
  // let colorBorder = "#24660a";
  // if (score <= 65) {
  //   color = "#ffcdcd";
  //   colorBorder = "#d6373a";
  // } else if (score > 65 && score <= 85) {
  //   color = "#ffffcb";
  //   colorBorder = "#c5a82f";
  // }
  return {
    colorScoreAvaliation: color,
    colorBorderScoreAvaliation: colorBorder,
    colorScoreReavaliation: color,
    colorBorderScoreReavaliation: colorBorder,
  };
};
export const getColorsScoreResolucaoTemporal = (
  score: any
) => {
  score = parseInt(score);
  const color = "#cbe0e433";
  const colorBorder = "#007085";
  // let color = "#e6ffdc";
  // let colorBorder = "#24660a";
  // if (score <= 75) {
  //   color = "#ffcdcd";
  //   colorBorder = "#d6373a";
  // } else if (score > 75 && score <= 90) {
  //   color = "#ffffcb";
  //   colorBorder = "#c5a82f";
  // }
  return {
    colorScoreAvaliation: color,
    colorBorderScoreAvaliation: colorBorder,
    colorScoreReavaliation: color,
    colorBorderScoreReavaliation: colorBorder,
  };
};
export const getColorsScoreOrdenacaoTemporal = (
  score: any
) => {
  score = parseInt(score);
  const color = "#cbe0e433";
  const colorBorder = "#007085";
  // let color = "#e6ffdc";
  // let colorBorder = "#24660a";
  // if (score <= 50) {
  //   color = "#ffcdcd";
  //   colorBorder = "#d6373a";
  // } else if (score > 50 && score <= 75) {
  //   color = "#ffffcb";
  //   colorBorder = "#c5a82f";
  // }
  return {
    colorScoreAvaliation: color,
    colorBorderScoreAvaliation: colorBorder,
    colorScoreReavaliation: color,
    colorBorderScoreReavaliation: colorBorder,
  };
};
export const getColorsScoreDicoticoSequencial = (
  score: any
) => {
  score = parseInt(score);
  const color = "#cbe0e433";
  const colorBorder = "#007085";
  // let color = "#e6ffdc";
  // let colorBorder = "#24660a";
  // if (score <= 65) {
  //   color = "#ffcdcd";
  //   colorBorder = "#d6373a";
  // } else if (score > 65 && score <= 90) {
  //   color = "#ffffcb";
  //   colorBorder = "#c5a82f";
  // }
  return {
    colorScoreAvaliation: color,
    colorBorderScoreAvaliation: colorBorder,
    colorScoreReavaliation: color,
    colorBorderScoreReavaliation: colorBorder,
  };
};
export const getColorsScoreDicoticoOd = (
  score: any
) => {
  score = parseInt(score);
  const color = "#cbe0e433";
  const colorBorder = "#007085";
  // let color = "#e6ffdc";
  // let colorBorder = "#24660a";
  // if (score <= 75) {
  //   color = "#ffcdcd";
  //   colorBorder = "#d6373a";
  // } else if (score > 75 && score <= 90) {
  //   color = "#ffffcb";
  //   colorBorder = "#c5a82f";
  // }
  return {
    colorScoreAvaliation: color,
    colorBorderScoreAvaliation: colorBorder,
    colorScoreReavaliation: color,
    colorBorderScoreReavaliation: colorBorder,
  };
};
export const getColorsScoreDicoticoOe = (
  score: any
) => {
  score = parseInt(score);
  const color = "#cbe0e433";
  const colorBorder = "#007085";
  // let color = "#e6ffdc";
  // let colorBorder = "#24660a";
  // if (score <= 70) {
  //   color = "#ffcdcd";
  //   colorBorder = "#d6373a";
  // } else if (score > 70 && score <= 85) {
  //   color = "#ffffcb";
  //   colorBorder = "#c5a82f";
  // }
  return {
    colorScoreAvaliation: color,
    colorBorderScoreAvaliation: colorBorder,
    colorScoreReavaliation: color,
    colorBorderScoreReavaliation: colorBorder,
  };
};

//INTERVALOS DE TEMPO DOS TESTES
export const getColorsTimeEvolucao = (
  score: any
) => {
  const colorTime = "";
  const colorBorderTime = "";
  return {
    colorTimeEvolucao: colorTime,
    colorBorderTimeEvolucao: colorBorderTime,
  };
};
export const getColorsTimeLocalizacaoSonora = (
  score: any
) => {
  score = parseInt(score);
  const colorTime = "#cbe0e433";
  const colorBorderTime = "#007085";
  // let colorTime = "#ffcdcd";
  // let colorBorderTime = "#d6373a";
  // if (score < 70) {
  //   colorTime = "#e6ffdc";
  //   colorBorderTime = "#24660a";
  // } else if (score >= 70 && score <= 115) {
  //   colorTime = "#ffffcb";
  //   colorBorderTime = "#c5a82f";
  // }
  return {
    colorTimeAvaliation: colorTime,
    colorBorderTimeAvaliation: colorBorderTime,
    colorTimeReavaliation: colorTime,
    colorBorderTimeReavaliation: colorBorderTime,
  };
};
export const getColorsTimeFechamentoOd = (
  score: any
) => {
  score = parseInt(score);
  const colorTime = "#cbe0e433";
  const colorBorderTime = "#007085";
  // if (score < 45) {
  //   colorTime = "#e6ffdc";
  //   colorBorderTime = "#24660a";
  // } else if (score >= 45 && score <= 100) {
  //   colorTime = "#ffffcb";
  //   colorBorderTime = "#c5a82f";
  // }
  return {
    colorTimeAvaliation: colorTime,
    colorBorderTimeAvaliation: colorBorderTime,
    colorTimeReavaliation: colorTime,
    colorBorderTimeReavaliation: colorBorderTime,
  };
};
export const getColorsTimeFechamentoOe = (
  score: any
) => {
  score = parseInt(score);
  const colorTime = "#cbe0e433";
  const colorBorderTime = "#007085";
  // if (score < 45) {
  //   colorTime = "#e6ffdc";
  //   colorBorderTime = "#24660a";
  // } else if (score >= 45 && score <= 100) {
  //   colorTime = "#ffffcb";
  //   colorBorderTime = "#c5a82f";
  // }
  return {
    colorTimeAvaliation: colorTime,
    colorBorderTimeAvaliation: colorBorderTime,
    colorTimeReavaliation: colorTime,
    colorBorderTimeReavaliation: colorBorderTime,
  };
};
export const getColorsTimeFiguraFundoOd = (
  score: any
) => {
  score = parseInt(score);
  const colorTime = "#cbe0e433";
  const colorBorderTime = "#007085";
  // if (score < 53) {
  //   colorTime = "#e6ffdc";
  //   colorBorderTime = "#24660a";
  // } else if (score >= 53 && score <= 90) {
  //   colorTime = "#ffffcb";
  //   colorBorderTime = "#c5a82f";
  // }
  return {
    colorTimeAvaliation: colorTime,
    colorBorderTimeAvaliation: colorBorderTime,
    colorTimeReavaliation: colorTime,
    colorBorderTimeReavaliation: colorBorderTime,
  };
};
export const getColorsTimeFiguraFundoOe = (
  score: any
) => {
  score = parseInt(score);
  const colorTime = "#cbe0e433";
  const colorBorderTime = "#007085";
  // if (score < 53) {
  //   colorTime = "#e6ffdc";
  //   colorBorderTime = "#24660a";
  // } else if (score >= 53 && score <= 90) {
  //   colorTime = "#ffffcb";
  //   colorBorderTime = "#c5a82f";
  // }
  return {
    colorTimeAvaliation: colorTime,
    colorBorderTimeAvaliation: colorBorderTime,
    colorTimeReavaliation: colorTime,
    colorBorderTimeReavaliation: colorBorderTime,
  };
};
export const getColorsTimeResolucaoTemporal = (
  score: any
) => {
  score = parseInt(score);
  const colorTime = "#cbe0e433";
  const colorBorderTime = "#007085";
  // if (score < 110) {
  //   colorTime = "#e6ffdc";
  //   colorBorderTime = "#24660a";
  // } else if (score >= 110 && score <= 160) {
  //   colorTime = "#ffffcb";
  //   colorBorderTime = "#c5a82f";
  // }
  return {
    colorTimeAvaliation: colorTime,
    colorBorderTimeAvaliation: colorBorderTime,
    colorTimeReavaliation: colorTime,
    colorBorderTimeReavaliation: colorBorderTime,
  };
};
export const getColorsTimeOrdenacaoTemporal = (
  score: any
) => {
  score = parseInt(score);
  const colorTime = "#cbe0e433";
  const colorBorderTime = "#007085";
  // if (score < 55) {
  //   colorTime = "#e6ffdc";
  //   colorBorderTime = "#24660a";
  // } else if (score >= 55 && score <= 100) {
  //   colorTime = "#ffffcb";
  //   colorBorderTime = "#c5a82f";
  // }
  return {
    colorTimeAvaliation: colorTime,
    colorBorderTimeAvaliation: colorBorderTime,
    colorTimeReavaliation: colorTime,
    colorBorderTimeReavaliation: colorBorderTime,
  };
};
export const getColorsTimeDicoticoSequencial = (
  score: any
) => {
  score = parseInt(score);
  const colorTime = "#cbe0e433";
  const colorBorderTime = "#007085";
  // if (score < 140) {
  //   colorTime = "#e6ffdc";
  //   colorBorderTime = "#24660a";
  // } else if (score >= 140 && score <= 250) {
  //   colorTime = "#ffffcb";
  //   colorBorderTime = "#c5a82f";
  // }
  return {
    colorTimeAvaliation: colorTime,
    colorBorderTimeAvaliation: colorBorderTime,
    colorTimeReavaliation: colorTime,
    colorBorderTimeReavaliation: colorBorderTime,
  };
};
export const getColorsTimeDicoticoOd = (
  score: any
) => {
  score = parseInt(score);
  const colorTime = "#cbe0e433";
  const colorBorderTime = "#007085";
  // if (score < 55) {
  //   colorTime = "#e6ffdc";
  //   colorBorderTime = "#24660a";
  // } else if (score >= 55 && score <= 80) {
  //   colorTime = "#ffffcb";
  //   colorBorderTime = "#c5a82f";
  // }
  return {
    colorTimeAvaliation: colorTime,
    colorBorderTimeAvaliation: colorBorderTime,
    colorTimeReavaliation: colorTime,
    colorBorderTimeReavaliation: colorBorderTime,
  };
};
export const getColorsTimeDicoticoOe = (
  score: any
) => {
  score = parseInt(score);
  const colorTime = "#cbe0e433";
  const colorBorderTime = "#007085";
  // if (score < 55) {
  //   colorTime = "#e6ffdc";
  //   colorBorderTime = "#24660a";
  // } else if (score >= 55 && score <= 85) {
  //   colorTime = "#ffffcb";
  //   colorBorderTime = "#c5a82f";
  // }
  return {
    colorTimeAvaliation: colorTime,
    colorBorderTimeAvaliation: colorBorderTime,
    colorTimeReavaliation: colorTime,
    colorBorderTimeReavaliation: colorBorderTime,
  };
};

export const getKeyGen = (key = "") => {
  return key + Math.random() + new Date().getMilliseconds();
};


export const keyGenerator = (key = "" as any) => {
  return key + Math.random() + new Date().getMilliseconds();
};

export const checkPasswordValidations = (
  pw: string | null = null,
  pw2: string | null = null
) => {
  const validation = {
    hasLowercase: false,
    hasUppercase: false,
    hasNumber: false,
    hasMinEight: false,
    hasSamePW: false,
  };
  const listLowercase = "qwertyuiopasdfghjklçzxcvbnm";
  const listUppercase = "QWERTYUIOPASDFGHJKLÇZXCVBNM";
  if (pw && pw !== "") {
    const tempPW = removeAscents(pw);
    validation.hasNumber = pw.replace(/\D/g, "").length > 0;
    validation.hasMinEight = pw.length >= 8;
    if (pw2 && pw2 !== "" && validation.hasMinEight) {
      validation.hasSamePW = pw === pw2;
    }
    tempPW.split("").forEach((char) => {
      if (!validation.hasLowercase)
        validation.hasLowercase = listLowercase.includes(char);
      if (!validation.hasUppercase)
        validation.hasUppercase = listUppercase.includes(char);
    });
  }
  return validation;
};

export const checkAllValidations = (
  pw1: string | null = null,
  pw2: string | null = null
) => {
  const {
    hasLowercase = false,
    hasUppercase = false,
    hasNumber = false,
    hasMinEight = false,
    hasSamePW = false,
  } = checkPasswordValidations(pw1, pw2);
  return hasLowercase && hasUppercase && hasNumber && hasMinEight && hasSamePW;
};

export const isSameText = (txt_1: string, txt_2: string) => {
  return String(txt_1).toLowerCase() === String(txt_2).toLowerCase();
};
export const isSameSimplestText = (txt_1: string, txt_2: string) => {
  txt_1 = simplestText(txt_1);
  txt_2 = simplestText(txt_2);
  return isSameText(txt_1, txt_2);
};

export const textToServer = (text: string) => {
  return text.toUpperCase();
};
export const toggleFullScreen = () => {
  try {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  } catch (error) {
    console.error("[error toggleFullScreen()", error);
  }
};

export const playAudioHandler = (
  audio: string,
  activeTest: string,
  volume: number,
  isPlaying: boolean | null,
  setIsPlaying: (set: boolean) => void | null,
  setHasPlayed?: (set: boolean) => void | null
) => {
  if (!isPlaying) {
    try {
      const playing = new Audio(GET_AUDIO_DIR(activeTest, audio));
      playing.addEventListener("ended", function () {
        playing.currentTime = 0;
        // if (setHasPlayed) setHasPlayed(true);
        try {
          if (volume) playing.volume = volume / 100;
        } catch (error) {
          console.error("[Error to set volume]", error);
        }
        setIsPlaying(false);
      });
      if (setHasPlayed) setHasPlayed(true);
      if (volume) playing.volume = volume / 100;
      playing.play();
      setIsPlaying(true);
    } catch (error) {
      console.error("[Error Play Audio activeTest]", error);
    }
  }
};

export const prettySubPathUrl = (path: string | null = null) => {
  if (path) {
    path = removeAscents(path).replaceAll(" ", "-").toLowerCase();
  }
  return String(path);
};

export const simplestText = (path: string | null = null) => {
  if (path) path = removeAscents(path).replaceAll(" ", "").toLowerCase();
  else path = "";
  return path;
};

export const getHiddenEmail = (email: string) => {
  let tempEmail = "";
  if (email) {
    try {
      tempEmail = email.split("@")[0];
      tempEmail += "@...";
    } catch (err: any) {
      console.error("[getTableBody] err", err.message);
    }
  }
  return tempEmail;
};

export const getLastProperty = (objeto: any) => {
  const chaves = Object.keys(objeto);
  if (chaves.length === 0) {
    return undefined; // Retorna undefined se o objeto estiver vazio
  }
  return chaves[chaves.length - 1];
}

export const isValidEmail = (email: string) => {
  const reg = /\S+@\S+\.\S+/;
  return reg.test(email);
};

export const isTrueFromPythonBoolean = (
  bool = "False" as string | boolean | null | undefined
) => {
  let is = false;
  if (bool) {
    bool = String(bool);
    if (isNaN(parseInt(bool))) is = isSameText(bool, "True");
    else is = Boolean(parseInt(bool));
  }
  return is;
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeAll(string: string) {
  return string.toUpperCase()
}

export const underlaneSplit = (s: string) => {
  const name = {
    "0": s.split("_")[0],
    "1": s.split("_")[1]
  }
  return name
}
const NOT_AUTHORIZED_EXCEPTION = "NotAuthorizedException";
const CODE_MISMATCH_EXCEPTION = "CodeMismatchException";
const USER_NOT_CONFIRMED_EXCEPTION = "UserNotConfirmedException";
const INVALID_PASSWORD_EXCEPTION = "InvalidPasswordException";
const INVALID_PARAMETER_EXCEPTION = "InvalidParameterException";
const USERNAME_EXISTS_EXCEPTION = "UsernameExistsException";
const LIMIT_EXCEEDED_EXCEPTION = "LimitExceededException";
export const getMsgErrorLogin = (error_code: string) => {
  let msg = "";
  switch (error_code) {
    // Login
    case NOT_AUTHORIZED_EXCEPTION:
      msg = "msg_error.NotAuthorizedException";
      break;
    // Verification Code
    case CODE_MISMATCH_EXCEPTION:
      msg = "msg_error.CodeMismatchException";
      break;
    case USER_NOT_CONFIRMED_EXCEPTION:
      msg = "msg_error.UserNotConfirmedException";
      break;
    // singup
    case INVALID_PASSWORD_EXCEPTION:
      msg = "msg_error.InvalidPasswordException";
      break;
    case INVALID_PARAMETER_EXCEPTION:
      msg = "msg_error.InvalidParameterException";
      break;
    case USERNAME_EXISTS_EXCEPTION:
      msg = "msg_error.UsernameExistsException";
      break;
    case LIMIT_EXCEEDED_EXCEPTION:
      msg = "msg_error.LimitExceededException";
      break;
    default:
      msg = "msg_error.unidentifiedException";
      break;
  }
  return msg;
};

export function copyFunc(
  param: any,
  encrypt: boolean,
  getUrl: boolean
) {
  if (getUrl) {
    const encryptMessage =
      AUDIOFOCO_LINK +
      "/login?link=" +
      encodeURIComponent(
        Crypto.AES.encrypt(JSON.stringify(param), SECRET_KEY).toString()
      );
    return encryptMessage;
  }
  if (encrypt) {
    const encryptMessage =
      AUDIOFOCO_LINK +
      "/login?link=" +
      encodeURIComponent(
        Crypto.AES.encrypt(JSON.stringify(param), SECRET_KEY).toString()
      );
    navigator.clipboard.writeText(encryptMessage);
    return encryptMessage;
  } else {
    navigator.clipboard.writeText(param);
  }
  setTimeout(() => { }, 1000);
}